import React, { useEffect, useState } from "react";
import FlexibleContent from "../components/reusable/FlexibleContent";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Styling } from "../styles/templates/student-success-post.styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import parse from "html-react-parser";
import Seo from "../components/global/seo";
import { RellaxWrapper } from "react-rellax-wrapper";

gsap.registerPlugin(ScrollTrigger);

export default function StudentSuccessPost({ data }) {
  const [image, setImage] = useState({
    heroType: "image",
    heroSrc: data?.hero?.acf_hero?.heroImage?.localFile?.publicURL,
  });

  console.log(data);

  useEffect(() => {
    let hero = null;
    let pinSpacer = null;

    const body = document.querySelector("body");
    hero = document.createElement("div");
    const imageEl = document.createElement("img");
    hero.classList.add("testimonials-hero");
    hero.classList.add("offscreen-translator");
    console.log(image);
    imageEl.setAttribute("src", image.heroSrc);
    body.appendChild(hero);
    hero.appendChild(imageEl);
    ScrollTrigger.create({
      trigger: ".scroll-content",
      pin: ".testimonials-hero",
      start: "top top",
      end: "bottom bottom",
      pinSpacing: false,
    });

    return () => {
      hero.remove();
      if (typeof window !== "undefined") {
        const spacers = document.querySelectorAll(".pin-spacer");
        spacers.forEach((spacer) => spacer.remove());
      }
    };
  }, [image]);

  return (
    <Styling>
      <Seo title={data.hero.seo.title} description={data.hero.seo.metaDesc} />
      <div className="scroll-content" style={{ minHeight: "100vh" }}>
        <GatsbyImage className={`mobileImg`} image={getImage(data?.hero?.acf_hero?.heroImage?.localFile)} alt={"Academy Live London 2025"} />

        <div className="testiIntro">
          <h2 className="main-title">{data.hero.title}</h2>
        </div>
        <FlexibleContent flexible={data.flexible} />
      </div>
    </Styling>
  );
}

export const query = graphql`
  query {
    hero: wpPage(title: { eq: "ACADEMY LIVE: LDN 2025" }) {
      title
      seo {
        metaDesc
        title
      }
      cookiesPrivacyIntroText {
        introTextCookiesPrivacy
      }
      acf_hero {
        heroImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
    flexible: wpPage(title: { eq: "ACADEMY LIVE: LDN 2025" }) {
      flexibleContent {
        postBuilder {
          ... on WpPage_Flexiblecontent_PostBuilder_TextBlock {
            fieldGroupName
            text
          }
          ... on WpPage_Flexiblecontent_PostBuilder_Quote {
            fieldGroupName
            quoteText
          }
          ... on WpPage_Flexiblecontent_PostBuilder_AudioPlayer {
            fieldGroupName
            trackTile
            audioFile {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Flexiblecontent_PostBuilder_ImageBlock {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP], layout: FULL_WIDTH)
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_PostBuilder_VideoBlock {
            fieldGroupName
            video
          }
        }
      }
    }
  }
`;
